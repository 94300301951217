
import axios_instance  from "./ApiService";


class CategoriesService {
    controllerName = '/Category';
    GetAllCategories(obj) {
        return axios_instance.get(this.controllerName + '/GetAllCategories' , obj).then(response =>{
            return Promise.resolve(response.data);
        });
    }

    SaveCategory(payload) {
        let form = new FormData();
        console.log(payload);
        form.append('NameAr' , payload.nameAr);
        form.append('NameEn' , payload.nameEn);
        form.append('CatActionLink' , payload.catActionLink);
        form.append('ImagePath' , payload.imagePath);
        if (payload.CategoryImage !== null)
            form.append('CategoryImage' , payload.CategoryImage);
        form.append('Id' , payload.id);
        delete axios_instance.defaults.headers.common['content-type'];
        return axios_instance.post(this.controllerName + '/SaveCategory' , form , {
            headers:{
                'content-type' : 'multipart/form-data'
            }
        }).then(response =>{
            return Promise.resolve(response.data);
        });
    }

    GetCategory(obj) {
        return axios_instance.get(this.controllerName + '/GetCategoryById' , {
            params:{
                Id:obj
            }
        }).then(response =>{
            return Promise.resolve(response.data);
        });
    }

    deleteCategory(id) {
        return axios_instance.delete(this.controllerName + '/DeleteCategory' , {
            params:{
                Id:id
            }
        }).then(response =>{
            return Promise.resolve(response.data);
        });
    }

    GetCategoryServices(id) {
        return axios_instance.get('/CategoryServices/GetCategoryServicesByCategoryId' , {
            params:{
                Id:id
            }
        }).then(response =>{
            return Promise.resolve(response.data);
        });
    }
    SaveCategoryService(payload) {
        return axios_instance.post('/CategoryServices/SaveServiceCategory' , payload).then(response =>{
            return Promise.resolve(response.data);
        });
    }
    deleteService(id) {
        return axios_instance.delete('/CategoryServices/DeleteCategoryService' , {params:{Id:id}}).then(response =>{
            return Promise.resolve(response.data);
        });
    }
}
export default new CategoriesService();