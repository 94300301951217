<template>
  <div class="pricing-box pa-3 pb-12">
    <h2>{{dataObj.name}}</h2>
    <span class="price">{{`${dataObj.balance} ${dataObj.currency.prefix}`}}</span>
    <p class="description" > <v-icon color="green">mdi-check</v-icon>{{`${dataObj.points} ${$t('point')}` }}</p>
    <span class="pricing-table-divider"></span>

    <p class="description" > <v-icon color="green">mdi-check</v-icon>{{`${dataObj.monthsNumber} ${$t('Month')}` }}</p>
    <span class="pricing-table-divider"></span>


    <a class="btn subscribe" @click="GoToSubscribe" ><b>{{$t('Subscribe') }}</b></a>
  </div>
</template>

<script>
export default {
  name: "PricingPlanSingle",
  props:{
    PointsPackage:Object,
  }
  ,data(){
    return {
      dataObj:this.PointsPackage
    }
  },
  methods:{
    GoToSubscribe(){
      this.$emit('Selected' , this.dataObj);
    }
  }
}
</script>

<style scoped>

a {
  text-decoration: none;
}
.pricing-table h2 {
  font-size: 1.5em;
  padding: 35px 10px;
  margin: 0;
}

.pricing-box:hover .price {
  background: #c44118;
  box-shadow: inset 0 0 100px 0 rgba(0, 0, 0, 0.3);
}
.price {
  background: rgb(76 178 201);
  color: #fff;
  font-size: 2em;
  font-weight: 600;
  position: relative;
  padding: 20px 10px;
  display: block;
}
.subscribe {
  background: rgb(76 178 201) ;
  color: white;
  -webkit-transition: all ease-out 0.2s;
  transition: all ease-out 0.2s;
  padding: 7px 40px;
  display: inline-block;
  margin-top: 20px;
}
.subscribe:hover {
  background: #c44118;
  border: 1px solid #c44118;
  color: #fff !important;
}
.pricing-box {
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.13);
  border: 2px solid rgba(10, 123, 183, 0.14);
}
.pricing-box:hover {
  -webkit-transform: scale(1.01);
  transform: scale(1.01);
}
.pricing-table-divider {
  display: block;
  height: 1px;
  background: rgba(0, 0, 0, 0.08);
  max-width: 80%;
  margin: 20px auto 0;
}
.description {
  margin-top: 20px;
  font-size: 0.85em;
  padding: 0 40px;
}

.price,
.pricing-box {
  -webkit-transition: all ease-out 0.2s;
  transition: all ease-out 0.2s;
}
</style>